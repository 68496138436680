.pin-order-filter-content {
  margin-bottom: 10px;
  padding: 10px;
  padding-top: 10px;
}
.filter {
  display: flex;
  justify-content: center;
}

.filter-content {
  display: flex;
  padding: 0px 20px 0px 20px;
  width: 100%;
  justify-content: space-between;
}
.filter-item {
  list-style: none;
  width: 48%;
}

.width-100-percent {
  width: 100%;
}

.filter-item:last-child {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;
  background-color: var(--page-light);
  border: 1px solid #ccc;
  z-index: 999;
}

.rdrDateRangePickerWrapper {
  display: inline-flex !important;
  user-select: none !important;
}

.filter-button {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.button {
  min-width: 115px;
  padding: 5px;
}

.button:first-child {
  margin-right: 20px;
}

.function-button-content {
  display: flex;
  justify-content: space-between;
  height: 40px;
  justify-items: baseline;
}

.pin-order-table-title {
  font-size: 20px;
}

.add-new-button {
  width: 150px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.add-new-icon {
  color: var(--page-light) !important;
}

.pin-order-title {
  font-weight: 500;
  font-size: 40px;
  color: #9c0d1a;
  text-align: center;
  background-color: var(--page-light);
}

.detail-order-container {
  display: flex;
  justify-content: space-around;
}

.detail-order-item {
  list-style: none;
  width: 49%;
  margin: 10px;
}

.create-pin-orders-body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.pin-orders-details,
.pin-orders-items {
  min-height: 40%;
  background-color: #fff;
  width: 49%;
}

.order-details-content {
  display: flex;
  padding: 0px 20px 0px 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.order-details-item {
  list-style: none;
}

.create-pin-orders-title {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.modal-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: auto;
  white-space: pre;
}

.attach-file-content {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.value-chosen-file {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: 'ellipsis';
  width: 100px;
}

.action-button-content {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
}

.attach-file-button {
  display: flex !important;
  justify-content: space-between !important;
  width: 130px;
  color: var(--page-light) !important;
  background-color: var(--primary-color) !important;
  margin: 0 auto !important;
}

.pin-orders-details-dark,
.pin-orders-items-dark {
  background-color: var(--page-dark) !important;
  color: var(--text-color-dark) !important;
}

.add-new-icon-dark {
  color: var(--text-color-dark) !important;
}

.error-message {
  display: flex;
  margin-top: 5px;
  flex-direction: row;
  color: red;
  justify-content: center;
}

.no-file {
  text-align: center;
  margin-top: 8px;
}

.delete-button {
  width: auto !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;
}

.delete-icon {
  color: #f4a1ac !important;
}

.delete-icon-dark {
  color: #959595 !important;
}

.view-btn {
  width: 38px !important;
  height: auto !important;
}

.question {
  white-space: break-spaces;
  font-size: 14px !important;
}

.comment-textarea {
  width: 100% !important;
  margin-top: 5px !important;
}

.submit-btn-spinner {
  margin-right: 16px !important;
}

@media screen and (max-width: 1023px) {
  .create-pin-orders-body {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pin-orders-details,
  .pin-orders-items {
    min-height: 40%;
    background-color: #fff;
    width: 100%;
  }
  .action-button-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .action-button-content > .button {
    margin-top: 5px;
    width: 100%;
  }

  .detail-order-item {
    width: 49%;
  }
  .filter-content {
    flex-direction: column;
  }
  .filter-item {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 739px) {
  .create-pin-orders-body {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pin-orders-details,
  .pin-orders-items {
    min-height: 40%;
    background-color: #fff;
    width: 100%;
  }
  .action-button-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .action-button-content > .button {
    margin-top: 5px;
    width: 100%;
  }
  .create-pin-orders-title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .detail-order-item {
    width: 49%;
  }
  .filter-content {
    flex-direction: column;
  }
  .filter-item {
    width: 100%;
    margin-left: 0;
  }
  .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .filter-button {
    padding: 0 40px 35px 40px;
    flex-direction: column;
  }
  .button {
    width: 100%;
  }
  .button:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .function-button-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
