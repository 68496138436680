.add-new-icon-dark {
  color: var(--text-color-dark) !important;
}

.view-btn {
  width: 38px !important;
  height: auto !important;
}

.delete-button {
  width: 30px !important;
  height: calc(1.35em + 1.1rem + 2px) !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin: 0 5px;
  padding: 10px;
}

.delete-icon {
  color: #f4a1ac !important;
}

.delete-icon-dark {
  color: #959595 !important;
}
