.wrapper {
  color: #3f4253;
  background-color: #ffffff;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}

.wrapper-dark {
  color: #bebfc2;
  background-color: #1e1e2c;
}
.contain-spinner {
  display: flex;
  justify-content: center;
}
.settlement-earnings-title {
  font-weight: 500;
  font-size: 40px;
  color: var(--primary-color);
  text-align: center;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.settlement-earnings-title-dark {
  background-color: #1e1e2c;
  color: #bebfc2;
}
.search-bar {
  display: flex;
  justify-content: flex-end;
}

.search-bar input:focus {
  border-color: var(--primary-color) !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.table-responsive > table {
  table-layout: auto !important;
  overflow: scroll;
  max-height: 480px;
}

@media screen and (max-width: 739px) {
  .search-bar {
    justify-content: center;
  }
  .pagination:first-child {
    margin-bottom: 10px;
  }
  .settlement-earnings-title {
    font-size: 35px;
    display: flex;
    justify-content: center;
  }
}
