.contact-us-wrapper {
  padding: 30px;

  .title {
    margin-bottom: 30px;
  }

  .content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .alert {
      padding: 0 !important;
    }

    .form-wrapper {
      padding-right: 50px;
      padding-left: 0;

      form {
        label {
          &.dark {
            color: #bebfc2;
          }

          font-size: 14px;
          font-weight: 600;
          align-self: center;
        }

        .file-arr-wrap {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .close-file-icon {
            cursor: pointer;
            margin: 10px;
          }

          .file-name-uploaded {
            margin: 0px;
            width: 28%;
            white-space: nowrap;
            width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .actions {
          margin-top: 20px;
          text-align: center;

          .upload-btn {
            max-width: 130px;
            width: 100%;
            margin-right: 10px;
          }

          .action-btn {
            &.dark {
              color: #bebfc2;
            }
            max-width: 240px;
            width: 100%;
          }
        }
      }
    }

    .resource-panel-wrapper {
      border-radius: 4px;
      border: 1px solid #d1d3e0;
      text-align: center;
      padding: 20px;
      height: fit-content;

      .content {
        margin-top: 20px;

        p {
          margin: 0;
          font-size: 16px;
        }
      }
    }
  }
}
