.button {
  min-width: 115px;
  padding: 5px;
}

.progress-bar-custom > div {
  background-color: var(--primary-color) !important;
}

@media screen and (max-width: 739px) {
  .button {
    width: 100%;
  }
}
