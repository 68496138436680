.function-button-content {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  justify-items: baseline;
}

.add-new-button {
  width: 120px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.user-create-container {
  list-style: none;
}

.user-create-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkbox-custom {
  margin-left: 11px;
  justify-content: flex-start;
}

.width-100-percent {
  width: 100%;
}

.width-90-percent {
  width: 90%;
}

.required-symbol {
  font-size: 20px;
  color: #dede1a;
  margin-top: 10px;
}

.question {
  white-space: break-spaces;
  font-size: 14px !important;
}

.modal-body {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: auto;
  white-space: pre;
}
