// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
  @import './_metronic/_assets/sass/themes/layout/header/base/dark.scss';
  @import './_metronic/_assets/sass/themes/layout/header/menu/dark.scss';
  @import './_metronic/_assets/sass/themes/layout/aside/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
  @import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
  @import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
  @import './_metronic/_assets/sass/themes/layout/aside/light.scss';
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none var(--page-light) !important;
}

.example-code > .json {
  background: none var(--page-light) !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: var(--primary-color) !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

[data-color-scheme='dark'] {
  color-scheme: dark;
  .MuiFormLabel-root,
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-disabled {
    color: var(--text-color-dark) !important;
  }

  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-disabled {
    & fieldset {
      border-color: #767e86;
    }
    &:hover fieldset {
      border-color: #767e86;
    }
    .MuiOutlinedInput-root.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: #f018a6;
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-color);
        border-width: 2px;
      }
    }

    .MuiSelect-icon {
      color: var(--text-color-dark);
    }
  }

  .rdrDateRangePickerWrapper {
    .rdrDefinedRangesWrapper {
      color: var(--text-color-dark) !important;
      background-color: var(--page-dark) !important;

      .rdrStaticRange {
        background-color: transparent !important;
        color: var(--text-color-dark) !important;
        &:hover {
          color: var(--primary-color) !important;
          background-color: var(--text-color-dark) !important;
        }
      }
      .rdrStaticRangeSelected {
        background-color: var(--text-color-dark) !important;
        color: var(--primary-color) !important;
      }
    }
    .rdrCalendarWrapper.rdrDateRangeWrapper {
      color: var(--text-color-dark) !important;
      background-color: var(--page-dark) !important;

      .rdrDay {
        background-color: var(--button-dark) !important;
        border: var(--button-border-dark) !important;
        color: var(--text-color-dark) !important;
        .rdrDayNumber span {
          color: var(--text-color-dark) !important;
        }
      }
      .rdrDateDisplayWrapper,
      .rdrMonthAndYearWrapper {
        background-color: var(--page-dark) !important;
        .rdrDateInput,
        select {
          background-color: var(--button-dark) !important;
          border: var(--button-border-dark) !important;
          color: var(--text-color-dark) !important;
        }
        .rdrDateDisplayItemActive {
          border: 1px solid var(--page-light) !important;
        }
      }
    }
  }
  .MuiPaper-root,
  .theme-menu {
    background-color: var(--page-dark) !important;
    color: var(--text-color-dark) !important;
    box-shadow: 0px 0px 20px 0px #000000b3 !important;
  }

  .MuiButtonBase-root {
    &:hover.MuiListItem-button {
      background-color: var(--button-dark);
    }
    &.Mui-selected {
      background-color: #5a5a5a;
    }
  }

  .MuiIconButton-root {
    color: var(--text-color-dark) !important;
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: var(--text-color-dark) !important;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      &.Mui-selected {
        background-color: transparent;
      }
    }
  }

  .MuiPickersClock-clock {
    .MuiPickersClockNumber-clockNumber {
      color: var(--text-color-dark) !important;

      &.MuiPickersClockNumber-clockNumberSelected {
        color: var(--text-color-light) !important;
      }
    }
  }

  .wrapper-element {
    color: var(--text-color-dark);
    background-color: var(--page-dark);
    border-radius: 10px;
    box-shadow: var(--shadow);
  }

  .modal-content {
    background-color: var(--page-dark) !important;
    color: var(--text-color-dark) !important;
    box-shadow: 0px 0px 20px 0px #000000b3 !important;
    .modal-header {
      border-bottom-color: #32383e;
      .modal-title {
        color: var(--text-color-dark) !important;
      }
    }
    .modal-footer {
      border-top-color: #32383e;
    }
  }

  .symbol {
    color: var(--page-dark);
  }

  .form-control {
    color: var(--text-color-dark) !important;
    background-color: var(--page-dark) !important;
    border-color: #767e86 !important;
    &:disabled {
      background-color: var(--button-dark) !important;
    }
  }

  .MuiPickersCalendarHeader-switchHeader {
    .MuiIconButton-root {
      background-color: #32383e;
    }
  }

  .MuiPickersCalendar-week {
    .MuiPickersDay-daySelected {
      color: var(--text-color-light) !important;
    }
  }
  .list-button-content,
  .list-export-button button,
  .list-button-content,
  .list-button-item button {
    background-color: var(--button-dark) !important;
    border: var(--button-border-dark) !important;
    color: var(--page-light) !important;
  }

  .list-export-button button:hover,
  .list-button-item .toggle-active,
  .list-button-item button:hover {
    color: var(--page-light) !important;
    background-color: var(--primary-color) !important;
  }

  .react-bootstrap-table .table.table-head-custom thead tr .sortable-active {
    color: var(--page-light) !important;
  }

  .svg-icon.svg-icon-primary svg g [fill],
  .svg-icon-danger {
    fill: var(--page-light) !important;
  }

  .row-expansion-style {
    color: var(--text-color-dark);
  }

  .page-title {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    color: var(--text-color-dark);
    background-color: var(--page-dark);
    margin-top: 5px;
  }

  .pagination-buttons a,
  .pagination-select,
  .pagination-select.disabled {
    background-color: var(--button-dark) !important;
    border: var(--button-border-dark) !important;
    color: var(--text-color-dark) !important;
  }

  .pagination-select {
    option {
      color: var(--text-color-dark) !important;
    }
  }

  .pagination {
    li {
      &.active {
        a {
          background-color: var(--primary-color) !important;
          color: #ffffff !important;
          border-color: var(--primary-color) !important;
        }
      }
      a {
        background-color: var(--button-dark) !important;
        border-color: var(--button-border-dark) !important;
        color: var(--text-color-dark) !important;
        &:hover.page-link {
          background-color: var(--primary-color) !important;
          border-color: var(--button-border-dark) !important;
          color: #ffffff !important;
        }
      }
    }
  }

  .label-light-success,
  .label-light-danger,
  .label-light-info,
  .label-light-primary {
    background-color: transparent;
    border: 2px solid #767e86;
    font-weight: bold;
  }

  .menu-dark {
    background-color: var(--page-dark);
    color: var(--text-color-dark) !important;
    box-shadow: 0px 0px 20px 0px #000000b3 !important;
  }

  .text-dark {
    color: var(--text-color-dark) !important;
  }

  .btn::after {
    color: var(--page-light) !important;
  }

  .btn-dark-primary-custom {
    color: var(--text-color-dark);
    background-color: var(--primary-color);
    border-color: transparent;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: var(--primary-color) !important;
    color: var(--text-color-dark) !important;
    div {
      color: var(--page-light) !important;
    }
    a {
      color: var(--text-color-dark) !important;
    }
  }

  .dropdown {
    .btn.btn-outline-light {
      border-color: transparent;
    }
  }

  .btn.btn-outline-light:hover:not(.btn-text):not(:disabled):not(.disabled),
  .btn.btn-outline-light:focus:not(.btn-text),
  .btn.btn-outline-light.focus:not(.btn-text) {
    background-color: var(--page-dark);
    border-color: transparent !important;
  }

  .btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text),
  .btn.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-light.dropdown-toggle,
  .show .btn.btn-outline-light.btn-dropdown {
    background-color: var(--page-dark) !important;
  }

  .table-striped {
    tbody {
      tr {
        color: var(--text-color-dark);
        &:nth-of-type(odd) {
          background-color: #2c3034;
        }
        td {
          border-top: 1px solid #272727;
        }
      }
    }
    thead {
      tr {
        color: var(--text-color-dark);
      }
    }
  }

  .add-new-icon {
    color: var(--text-color-dark) !important;
  }

  .text-align-center {
    text-align: center;
    color: var(--text-color-dark) !important;
  }

  .assigned-agent-label-light {
    background-color: transparent !important;
    color: var(--text-color-dark);
    border: 2px solid #a3a3a5;
  }

  .assigned-agent-label-light:hover {
    border: 2px solid #edeef2;
  }

  .assigned-agent {
    position: relative;
    z-index: 999;
  }
  .assigned-agent:hover {
    background-color: #e0e0e0;
  }

  .assigned-agent:hover::after {
    content: '';
    position: absolute;
    left: 100%;
    transform: translateX(-55%);
    border-width: 13px;
    border-style: solid;
    z-index: 1001;
    border-color: transparent var(--page-dark) transparent transparent;
  }

  .assigned-agent:hover::before {
    content: '';
    width: 20px;
    height: 150px;
    position: absolute;
    left: 100%;
  }

  .popup-information {
    display: none;
  }

  .assigned-agent:hover .popup-information {
    position: absolute;
    left: 100%;
    transform: translateX(3%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 200px;
    z-index: 1000;
    color: var(--text-color-dark);
    background-color: var(--page-dark);
    box-shadow: 1px -1px 1rem 4px rgb(190 190 190 / 64%);
    border-radius: 5px;
    height: 150px;
    overflow-y: scroll;
  }

  @media screen and (max-width: 1023px) {
    .page-title {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }
}

[data-color-scheme='light'] {
  color-scheme: light;
  .MuiFormLabel-root,
  .MuiOutlinedInput-root {
    color: var(--text-color-light) !important;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }

    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23);
    }
    .MuiOutlinedInput-root.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: #f018a6;
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--primary-color);
        border-width: 2px;
      }
    }
  }

  .symbol {
    color: var(--page-light);
    font-size: 20px;
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: #edeef2;
  }

  .wrapper-element {
    color: var(--text-color-light);
    background-color: var(--page-light);
    border-radius: 10px;
    box-shadow: var(--shadow);
  }

  .page-title {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--page-light);
    margin-top: 5px;
  }

  .dropdown-menu > li > a {
    color: var(--text-color-light) !important;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: var(--primary-color) !important;
    color: var(--page-light) !important;
    div {
      color: var(--page-light) !important;
    }
    a {
      color: var(--page-light) !important;
    }
  }

  .svg-icon.svg-icon-primary svg g [fill],
  .svg-icon-danger {
    fill: var(--primary-color) !important;
  }

  .add-new-icon {
    color: var(--page-light) !important;
  }

  .form-control:focus {
    border-color: var(--primary-color) !important;
  }
  .pagination {
    li {
      &.active {
        a {
          background-color: var(--primary-color) !important;
          color: #ffffff !important;
          border-color: var(--primary-color) !important;
        }
      }
      a {
        background-color: var(--button-light) !important;
        border-color: var(--button-border-light) !important;
        color: var(--primary-color) !important;
        &:hover.page-link {
          background-color: var(--primary-color) !important;
          border-color: var(--primary-color) !important;
          color: #ffffff !important;
        }
      }
    }
  }

  .react-bootstrap-table .table.table-head-custom thead tr .sortable-active {
    color: var(--primary-color) !important;
  }

  .text-align-center {
    text-align: center;
    color: var(--primary-color) !important;
  }

  .assigned-agent-label-light {
    background-color: #d3d3d3 !important;
    color: var(--text-color-light);
  }

  .assigned-agent-label-light:hover {
    background-color: #e2e0e0 !important;
    color: var(--text-color-light);
  }

  .assigned-agent {
    position: relative;
    z-index: 999;
  }
  .assigned-agent:hover {
    background-color: #e0e0e0;
  }

  .assigned-agent:hover::after {
    content: '';
    position: absolute;
    left: 100%;
    transform: translateX(-55%);
    border-width: 13px;
    border-style: solid;
    z-index: 1001;
    border-color: transparent #ffffff transparent transparent;
  }

  .assigned-agent:hover::before {
    content: '';
    width: 20px;
    height: 150px;
    position: absolute;
    left: 100%;
  }

  .popup-information {
    display: none;
  }

  .assigned-agent:hover .popup-information {
    position: absolute;
    left: 100%;
    transform: translateX(3%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 200px;
    z-index: 1000;
    color: black;
    background-color: #ffffff;
    box-shadow: 0px 0px 1rem 3px rgb(0 0 0 / 23%);
    border-radius: 5px;
    height: 150px;
    overflow-y: scroll;
  }

  @media screen and (max-width: 1023px) {
    .page-title {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }
}

.cursor-alias {
  cursor: default !important;
}

.btn-dark {
  background-color: var(--button-dark) !important;
  border: var(--button-border-dark) !important;
  color: var(--text-color-dark) !important;
}

.pagination-dark button,
.pagination-dark ul,
.dropdown-dark {
  background-color: var(--button-dark) !important;
  border: var(--button-border-dark) !important;
  color: var(--text-color-dark) !important;
}

.pagination-dark li a,
.pagination-dark button::after,
.dropdown-item-dark {
  color: var(--text-color-dark) !important;
}

.pagination-dark li:hover a,
.dropdown-item-dark:hover {
  color: var(--primary-color) !important;
}

.pagination-dark li:hover {
  background-color: var(--text-color-dark) !important;
  color: var(--text-color-dark) !important;
}

.search-bar-input-dark {
  background-color: var(--page-dark) !important;
  color: var(--text-color-dark) !important;
}

.row-dark {
  color: var(--text-color-dark);
}

.table-responsive > table {
  table-layout: auto !important;
  overflow: scroll;
  max-height: 480px;
}

.toggle-list {
  height: 200px;
  overflow-y: scroll;
  background-color: var(--page-light);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.action-list {
  height: fit-content;
  background-color: var(--page-light);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.list-button-content {
  margin-bottom: -19px;
}

.list-button-item {
  width: 150px;
  list-style: none;
  margin-bottom: 5px;
}

.list-export-button {
  list-style: none;
  margin-bottom: 5px;
}

.toggle-active {
  background-color: var(--primary-color) !important;
  color: var(--page-light) !important;
}

.theme-menu {
  padding: unset !important;
}

.no-transaction {
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--text-color-light);
  background-color: #b3b3b4;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 20px;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: #767e86 !important;
}
.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  color: #767e86 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--primary-color) !important;
}

.MuiCheckbox-colorPrimary.Mui-disabled {
  color: #edeef2 !important;
}

.logo {
  height: 100px;
  background: var(--logo);
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  background-size: contain;
  margin-bottom: 20px;
}

.btn-primary-custom {
  background-color: var(--primary-color) !important;
  color: #ffffff;
  border-color: var(--primary-color) !important;
}

.btn-primary-custom:hover:enabled {
  opacity: 0.6 !important;
}
.btn-primary-custom:hover {
  opacity: 0.6 !important;
}

.btn-secondary-custom {
  background-color: var(--secondary-color) !important;
  color: #ffffff !important;
  border-color: var(--secondary-color) !important;
}

.btn-secondary-custom:hover:enabled {
  opacity: 0.6 !important;
}
.checkbox-custom > input:checked ~ span {
  background-color: var(--primary-color) !important;
}

.text-hover-primary-custom:hover {
  color: var(--primary-color) !important;
}

.btn-hover-primary-custom:hover {
  background-color: var(--primary-color) !important;
  color: var(--page-light) !important;
}

.btn-hover-primary-custom:hover > i {
  color: var(--page-light) !important;
}

.active-custom {
  background-color: var(--primary-color) !important;
  color: var(--page-light) !important;
}

.btn.btn-hover-primary:not(:disabled):not(.disabled).active {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.active-theme,
.active-theme div {
  color: var(--primary-color) !important;
}

.active-theme div {
  color: var(--primary-color) !important;
}

.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-danger:focus:not(.btn-text),
.btn.btn-hover-danger.focus:not(.btn-text) {
  fill: var(--page-light) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #ffffff;
}

.transform-component-module_wrapper__1_Fgj {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  min-height: 650px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

#agent-limit-tooltip > .tooltip-inner {
  width: 300px;
  max-width: 300px;
}
