.backdrop {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 100px;
}

.payout-transaction-wrapper {
  padding: 30px;
  margin-bottom: 20px;

  .title {
    text-align: center;
    background-color: var(--page-light);
    color: var(--primary-color);
    margin-bottom: 30px;
  }

  .title-dark {
    text-align: center;
    background-color: var(--page-dark);
    color: var(--text-color-dark);
    margin-bottom: 30px;
  }

  .table-dark,
  .thead-dark {
    color: var(--text-color-dark) !important;
    background-color: var(--page-dark) !important;
  }

  .table-dark td,
  .table-dark th {
    border-color: var(--border-td-th-table) !important;
  }

  .payout-transaction-table {
    margin-top: 15px;
  }

  .payout-report-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .payout-report-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .successful-payout {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .success-payout-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .value {
      font-size: 16px;
      margin-left: 25px;
      margin-bottom: 0px;
    }
  }
}

@media screen and (max-width: 739px) {
  .payout-transaction-wrapper {
    .payout-report-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
    }

    .payout-report-item:first-child {
      margin-bottom: 25px;
    }
  }
}
