//
// Prismjs
//


// Variables
$example-spacer-y: 1.75rem;
$example-spacer-x: 1.5rem;

// Example
.example {
    // Base
    .example-tools {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .example-toggle,
    .example-copy {
        height: 34px;
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.25rem;
        transition: $transition;
        cursor: pointer;
        font-size: 1.15rem;
        color: $gray-500;

        &:hover {
            transition: $transition;
            color: var(--primary-color);
        }
    }

    .example-toggle {
        font-size: 1.35rem;

        &:after {
            @include ki(get($ki-types, code));
        }

        &.example-toggled {
            background-color: $gray-100;
            transition: $transition;
            color: var(--primary-color);
            @include border-top-left-radius($border-radius);
            @include border-top-right-radius($border-radius);

            &:after {
                content:  '#{get($ki-types, hide)}';
            }
        }
    }

    .card-toolbar .example-toggle {
        @include border-radius($border-radius);
    }

    .example-copy {
        &:after {
            @include ki(get($ki-types, copy));
        }

        &.example-copied {
            transition: $transition;
            color: var(--primary-color);

            &:after {
                content: get($ki-types, check);
            }
        }
    }

    .example-preview {
        border: 4px solid $gray-100;
        padding: $example-spacer-y $example-spacer-x;
        @include border-top-left-radius($border-radius);
        @include border-top-right-radius($border-radius);
    }

    .example-nav.nav {
        padding: 0 $example-spacer-x;

        .nav-item {
            margin: 0;
            margin-right: 1rem;
        }

        .nav-link {
            padding: 1rem 0;
        }
    }

    .example-code {
        padding: 0;
        position: relative;
        background-color: $gray-100;
        @include border-radius($border-radius);

        .example-copy {
            position: absolute;
            top: 0.25rem;
            right: calc($example-spacer-x / 2);

            &:hover {
                color: var(--primary-color);
                transition: $transition-link;
            }
        }

        .example-highlight {
            padding: $example-spacer-y $example-spacer-x;
        }

        .tab-pane {
            .example-highlight {
                padding: $example-spacer-y $example-spacer-x;
            }
        }

        .example-nav + .example-copy {
            top: 0.25rem;
        }

        pre[class*="language-"] {
            margin: 0;
            overflow: auto;
            padding: 0;
            background-color: $gray-100;
            @include border-radius($border-radius);

            code {
                padding: 0;
                margin: 0;
            }
        }
    }

    .example-preview + .example-code {
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);

        .example-copy {
            top: 0rem;
            height: 25px;
        }

        pre[class*="language-"] {
            @include border-top-left-radius(0);
            @include border-top-right-radius(0);
        }
    }

    // Compact mode
    &.example-compact {
        position: relative;

        .example-toggle {
            &.example-toggled {
                background-color: $gray-100;
            }
        }

        .example-code {
            background-color: $gray-100;

            pre[class*="language-"] {
                background-color: $gray-100;
            }

            &:not(.example-code-on) {
                position: absolute;
                left: -1000rem;
                top: -1000rem;
                opacity: 0;
            }

            &.example-code-on {
                display: none;
            }

            @include border-radius($border-radius);
        }

        .example-preview + .example-code {
            @include border-top-left-radius(0);
            @include border-top-right-radius(0);
        }
    }
}
