.wrapper {
  color: #3f4253;
  background-color: #ffffff;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}

.wrapper-dark {
  color: #bebfc2;
  background-color: #1e1e2c;
}
.contain-spinner {
  display: flex;
  justify-content: center;
}

.resource-management-title {
  font-weight: 500;
  font-size: 40px;
  color: #9c0d1a;
  text-align: center;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.resource-management-title-dark {
  background-color: #1e1e2c;
  color: #bebfc2;
}

.search-bar {
  display: flex;
  justify-content: flex-end;
}

.search-bar input:focus {
  border-color: #4ab58e !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.table-responsive > table {
  table-layout: auto !important;
  overflow: scroll;
  max-height: 480px;
}

.add-new-button {
  width: 150px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.add-new-icon {
  color: var(--page-light) !important;
}

.add-new-icon-dark {
  color: var(--text-color-dark) !important;
}

.container-table-header {
  display: flex;
  justify-content: flex-end;
}

.create-document-content {
  display: flex;
  padding: 0px 20px 0px 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.create-document-item {
  list-style: none;
}

.width-100-percent {
  width: 100%;
}

.action-button {
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.center {
  display: flex;
  justify-content: center;
}

.upload-btn {
  max-width: 130px;
  width: 100%;
  margin-right: 10px;
}

.actions {
  text-align: center;
}

.error-message {
  align-self: center;
  color: red;
}

.submit-button {
  width: 125px !important;
}

.filter-container {
  display: grid;
  padding: 0px 20px 0px 20px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 20px;
}

.filter-item {
  list-style: none;
}

.search-button {
  margin-right: 20px;
}

.action-button-search {
  display: flex;
  justify-content: center;
}

.resource-management {
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow: hidden !important;
}

@media screen and (max-width: 739px) {
  .pagination:first-child {
    margin-bottom: 10px;
  }
  .resource-management-title {
    font-size: 35px;
    display: flex;
    justify-content: center;
  }

  .container-table-header {
    flex-direction: column;
    align-items: center;
  }
  .action-button {
    flex-direction: column;
  }
  .button-block {
    margin-top: 10px;
    width: 100%;
  }

  .filter-container {
    display: grid;
    padding: 0px 20px 0px 20px;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0 20px;
  }

  .action-button-search {
    flex-direction: column;
    padding: 0 20px;
  }

  .search-button {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
