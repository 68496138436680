.filter {
  display: flex;
  justify-content: center;
}

.filter-content {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 20px;
  width: 100%;
  height: 175px;
  flex-wrap: wrap;
}
.filter-content-sales-reports {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  flex-wrap: wrap;
}
.filter-item-sales-reports {
  list-style: none;
  width: 99%;
}
.filter-item {
  margin-top: -18px;
  list-style: none;
  width: 49%;
}
.filter-item-multiple-select-custom {
  list-style: none;
  width: 49%;
  margin: 0px;
  height: 50px;
}

.width-100-percent {
  width: 100%;
}

.filter-button {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.button {
  min-width: 115px;
  padding: 5px;
}

.button:first-child {
  margin-right: 20px;
}

/* Notice */
.notice {
  padding: 10px;
  text-align: center;
  word-wrap: break-word;
}

.top-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.left-content {
  width: 80%;
  background-color: var(--page-light);
  flex: 4;
}

.right-content {
  width: 20%;
  margin-left: 20px;
  background-color: var(--page-light);
  padding: 20px;
  flex: 1;
}

.reports-title {
  font-weight: 500;
  font-size: 40px;
  color: #9c0d1a;
  text-align: center;
  background-color: var(--page-light);
  margin-bottom: 0;
}
.function-button-content {
  display: flex;
  justify-content: space-between;
  height: 40px;
  justify-items: baseline;
}

.report-table-title {
  font-size: 20px;
}

.report-table-button {
  display: flex;
  width: 360px;
}

.function-button-item {
  position: relative;
  width: 170px;
  margin-right: 20px;
}
.column-visibility-button,
.operations-button {
  display: flex !important;
  justify-content: space-between;
  min-width: 170px;
  height: 40px;
}

.function-button-item,
.column-visibility-button,
.operations-button {
  width: 100%;
}

.active {
  background-color: #9b0e1c !important;
  color: var(--page-light) !important;
}

.column-visibility-button-title,
.action-button-title {
  display: block;
  width: 114px;
}

.wrap,
.action-wrap {
  padding-top: 10px;
  position: absolute;
  display: none;
  top: 38px;
  right: 0;
  left: 0;
  height: 360px;
  z-index: 1;
  background-color: transparent;
}

.wrap:hover,
.action-wrap:hover {
  display: block;
}

.column-visibility-button:hover + .wrap,
.operations-button:hover + .action-wrap {
  display: block;
}

.caret {
  position: absolute;
  top: 15px;
  right: 14px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid #fff;
  border-right: solid transparent;
  border-left: solid transparent;
  border-width: 7px;
}

.list-export-button {
  list-style: none;
  margin-bottom: 5px;
}

.not-expand {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #9c0d1b;
  border: 1px solid rgb(129, 129, 57);
}
.not-expand > span {
  color: var(--page-light);
}

.table-responsive > table {
  table-layout: auto !important;
  overflow: scroll;
  max-height: 480px;
}

.expand {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #9c0d1b;
  border: 1px solid rgb(129, 129, 57);
}
.expand > span {
  color: var(--page-light);
}

.highlightOption {
  color: unset;
  background: transparent;
}

.refund-transaction-info {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  align-items: center;
  width: 100%;
}

.wrapper li {
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 152px;
}

.wrapper li label {
  font-weight: 600;
  min-width: 105px;
  margin-bottom: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #ebedf3;
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  justify-items: center;
}

.modal-footer-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modal-footer-actions .button {
  margin-top: 5px;
}

@media (hover: hover) and (pointer: fine) {
  .btn-item:hover {
    background-color: var(--primary-color);
    color: var(--page-light) !important;
  }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 739px) {
  .filter-item-sales-reports {
    width: 100%;
  }
  .reports-title {
    font-size: 35px;
  }

  .report-table-title {
    display: flex;
    justify-content: center;
  }

  .top-content {
    flex-direction: column-reverse;
    justify-items: center;
    width: 100%;
  }
  .left-content {
    margin-right: 0;
    width: 100%;
  }
  .right-content {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .send-mail-button {
    margin-bottom: 20px;
  }

  .report-table-button {
    flex-direction: column;
    width: 100%;
    height: 95px;
  }
  .list-button-item {
    width: 100%;
    border-bottom: 1px solid rgb(219, 214, 214) !important;
  }
  .function-button-content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 150px;
  }
  .function-button-item,
  .column-visibility-button,
  .operations-button {
    margin-top: 5px;
    width: 100%;
  }

  .filter-content {
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  }
  .filter-item {
    width: 100%;
  }
  .filter-item-multiple-select-custom {
    width: 100%;
  }

  .filter-item-period {
    order: 5;
  }
  .filter-item-from {
    order: 3;
  }
  .filter-item-to {
    order: 4;
  }

  .filter-button {
    padding: 0 40px 35px 40px;
    flex-direction: column;
  }
  .button {
    width: 100%;
  }
  .button:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .modal-footer-actions {
    margin-top: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .modal-footer-actions {
    margin-top: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .modal-footer {
    flex-direction: column;
    align-items: center;
  }
  .button-block {
    width: 100%;
  }
}
