.performance-report {
  .filter-wrapper {
    padding: 2.25rem;
    margin-bottom: 10px;

    .filter {
      .select-wrapper {
        text-align: center;

        .select {
          text-align: start !important;
          max-width: 500px !important;
        }
      }

      .actions,
      .sub-agents {
        text-align: center;
        align-self: center;

        .reset-btn {
          background-color: #c9cece;

          &.dark {
            color: white;
            background-color: #8e8f8f;
          }
        }

        .button-submit {
          width: 150px;
          margin-right: 10px;
        }
      }
    }
  }

  .table-wrapper {
    padding: 2.25rem;

    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 500;
    }

    .transaction-table-button {
      display: flex;
      justify-content: end;
    }

    .function-button-content {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      background: red;
    }

    .function-button-item {
      position: relative;
      width: 200px;
      margin-left: 35px;
    }

    .wrap,
    .action-wrap {
      padding-top: 10px;
      position: absolute;
      display: none;
      top: 38px;
      right: 0;
      left: 0;
      height: 360px;
      z-index: 1;
      background-color: transparent;
    }

    .wrap:hover,
    .action-wrap:hover {
      display: block;
    }

    .column-visibility-button:hover+.wrap,
    .operations-button:hover+.action-wrap {
      display: block;
    }

    .caret {
      margin-left: 5px;
      top: 15px;
      right: 14px;
      display: inline-block;
      box-sizing: border-box;
      border-top: solid #fff;
      border-right: solid transparent;
      border-left: solid transparent;
      border-width: 7px;
    }

    .operations-button {
      margin-left: 77px;
    }
  }

  .text-align-center {
    text-align: center;
  }
}

@media screen and (max-width: 739px) {

  .function-button-item,
  .column-visibility-button,
  .operations-button {
    margin: 0px !important;
    width: 100% !important;
    justify-content: space-between !important;
    display: flex !important;
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn-item:hover {
    background-color: var(--primary-color);
    color: var(--page-light) !important;
  }
}