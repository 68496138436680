.display-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.user-profile-content {
  display: flex;
  padding: 20px;
}

.child-content {
  border-radius: 10px;
  margin-right: 10px;
  padding: 20px;
  padding-right: 5%;
  padding-left: 5%;
  width: 50%;
}

.child-content:last-child {
  background-color: transparent;
  padding: 0;
  margin-right: 0;
  margin-left: 10px;
}

.height-50-percent {
  height: 50%;
}

.height-100-percent {
  height: 100%;
}

.padding-20 {
  padding: 20px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d1d3e0;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dark {
  background-color: var(--page-dark) !important;
  color: var(--text-color-dark) !important;
  border-color: #767e86;
}

.form-control-dark:disabled {
  background-color: #333339 !important;
  /* border-color: #767e86 !important; */
}

.form-control:disabled {
  background-color: #f3f6f9;
  opacity: 1;
}

.form-control:focus {
  color: #3f4254;
  background-color: #ffffff;
  border-color: #17c191;
  outline: 0;
}

.error {
  border-color: #fd6972;
}

.error:focus {
  border-color: #fd6972;
}

.btn-enable {
  background-color: #1ddb46 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.btn-enable:hover {
  background-color: #9c0d1a !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.btn-disable {
  background-color: #9c0d1a !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.btn-disable:hover {
  opacity: 0.7;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.align-self-center {
  align-self: center;
}

.space-evelyn {
  justify-content: space-evenly;
}

.width-50-percent {
  width: 50%;
}

.width-100-percent {
  width: 100%;
}

.align-item-baseline {
  align-items: baseline;
}

.flex-direction-column {
  flex-direction: column;
}

.form-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1.75rem;
}

.title-logo {
  width: 120px;
  height: 120px;
  background-color: rgb(201, 247, 245);
  border: 3px solid rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 8%) 0px 0.5rem 1.5rem 0.5rem;
}

.title-logo > span {
  font-size: 60px;
  line-height: 100%;
  align-self: center;
}

.title-input {
  min-width: 120px;
}

.title-input-dark {
  color: var(--text-color-dark) !important;
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.header-child-content > p {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.header-child-content-light > p {
  color: var(--primary-color);
}

.title:first-child {
  word-wrap: break-word;
}

.title > span {
  font-weight: 400;
}

.qr-code {
  width: 50%;
  min-width: 200px;
  height: 50%;
  min-height: 200px;
}

.button {
  min-width: 115px;
  padding: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.required-field {
  position: relative;
}

.required-symbol {
  font-size: 20px;
  color: #dede1a;
  margin-top: 8px;
  position: absolute;
  right: -25px;
}

@media screen and (max-width: 1023px) {
  .user-profile-content {
    flex-direction: column;
  }

  .child-content {
    width: 100%;
    margin-right: 0;
    padding: 20px 10%;
  }

  .child-content:last-child {
    margin-left: 0;
    margin-top: 20px;
  }

  .form-button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .form-button > button {
    margin-right: 0 !important;
  }

  .form-button > button:last-child {
    margin-top: 5px;
  }
}

@media screen and (max-width: 739px) {
  .form-label-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .required-symbol {
    margin-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .form-label-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .required-symbol {
    right: -15px;
  }
}
