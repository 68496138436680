.button {
  min-width: 115px;
  padding: 5px;
  color: white;
  background-color: #f54c64;
}

.warning-color {
  color: #f54c64;
}

.warning-color :first-child {
  color: #f54c64;
}

@media screen and (max-width: 739px) {
  .button {
    width: 100%;
  }
}
