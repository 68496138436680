.notice {
  padding: 10px;
  text-align: center;
  word-wrap: break-word;
}

.download {
  font-size: 26px;
  margin: 10px;
}

.btn-download {
  background-color: var(--primary-color);
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  margin: auto;
  color: white;
  border: 1px solid var(--primary-color);
}

.btn-download:hover {
  opacity: 0.8 !important;
  transition: all ease 0.5s;
}
