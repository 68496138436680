body.swal2-height-auto {
	#root {
		height: 100vh !important;
	}
}

.swal2-container {
	.swal2-popup.swal2-modal{
		.swal2-actions {
			.swal2-confirm.swal2-styled {
				background-color: var(--primary-color);
			
				&:focus {
					box-shadow: none !important;
				}
			}
		}
		
		.swal2-html-container {
			font-size: 13px !important;
		}
	}
}

