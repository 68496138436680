/* RESET STYLES & HELPER CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --level-1: #4ab58e;
  --level-2: #8950fc;
  --level-3: #7b9fe0;
  --level-4: #f27c8d;
  --brown: #917151;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ol {
  list-style: none;
}

body {
  margin: 50px 0 100px;
  text-align: center;
}

.rectangle {
  user-select: none;
  cursor: pointer;
  text-align: center;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.rectangle h3 {
  padding: 20px;
}
.rectangle p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 1.1rem;
}

/* LEVEL-1 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-1 {
  width: 400px;
  margin: 0 auto 40px;
  background: var(--level-1);
}

.level-1-has-children::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: var(--brown);
}

.level-1 h3 {
  font-size: 1.1rem;
  color: #ffffff;
}

.level-1:hover {
  background-color: #3f9e7b;
}

/* LEVEL-2 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-2-wrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}

.level-2-wrapper li {
  width: 50%;
  position: relative;
}

/* .level-2-wrapper::before {
  content: '';
  position: absolute;
  top: -20px;
  width: 100%;
  left: 20%;
  height: 2px;
  background: var(--brown);
} */

.level-2-wrapper::after {
  display: none;
  content: '';
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: calc(100% + 20px);
  height: 2px;
  background: var(--brown);
}

.level-2-wrapper > li::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: var(--brown);
}

.level-2-wrapper > li::after {
  content: '';
  position: absolute;
  top: -20px;
  height: 2px;
  background: var(--brown);
}

.level-2-wrapper > li:first-child:not(:only-child)::after {
  width: 70%;
  left: 50%;
}

.level-2-wrapper > li:not(:first-child):not(:last-child):after {
  width: 100%;
}

.level-2-wrapper > li:last-child:not(:only-child)::after {
  width: 70%;
  right: 50%;
}

.level-2 {
  width: 200px;
  margin: 0 auto 40px;
  background: var(--level-2);
  font-size: 11px;
}

.level-2-has-children::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: var(--brown);
}

.level-2::after {
  display: none;
  content: '';
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: var(--brown);
}

.level-2 h3 {
  font-size: 1.1rem;
  color: #ffffff;
}

.level-2:hover {
  background-color: #7c41f5;
}

/* LEVEL-3 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-3-wrapper {
  content: '';
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.level-3 {
  width: 90%;
  margin: auto;
  z-index: 99;
  background: var(--level-3);
}

/* .level-3-wrapper::before {
  content: '';
  position: absolute;
  top: -20px;
  left: calc(12% - 5px);
  width: calc(75% + 10px);
  height: 2px;
  background: var(--brown);
} */
.level-3-wrapper > li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: var(--brown);
}

.level-3-wrapper > li:first-child:not(:only-child):after {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 100%;
  height: 2px;
  background: var(--brown);
}

.level-3-wrapper > li:not(:first-child):not(:last-child):after {
  content: '';
  position: absolute;
  top: -20px;
  width: 100%;
  height: 2px;
  background: var(--brown);
}

.level-3-wrapper > li:last-child:not(:only-child):after {
  content: '';
  position: absolute;
  top: -20px;
  right: 50%;
  width: 100%;
  height: 2px;
  background: var(--brown);
}

.level-3-wrapper > li {
  margin: 0 auto;
}

.level-3 h3 {
  font-size: 1.1rem;
  color: #ffffff;
}

.level-3:hover {
  opacity: 0.8;
}

.notice {
  padding: 10px;
  text-align: center;
  word-wrap: break-word;
}

.wrapper {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
}

.wrapper li {
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  min-width: 152px;
}

.wrapper li label {
  font-weight: 600;
  min-width: 105px;
  margin-bottom: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.input-group {
  width: 240px;
  border-color: var(--primary-color);
}

.company-detail-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}

.company-structure {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.tool-box {
  margin-right: 5px;
  position: absolute;
  top: 0;
  display: flex;
  right: 0;
  z-index: 1;
  justify-content: space-evenly;
  flex-direction: column;
}

.tool-box > a {
  margin-bottom: 5px;
}

.tool-box > div {
  width: 38.38px;
  height: 38.38px;
  background-color: #9c0d1a;
  color: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.contain-spinner {
  display: flex;
  justify-content: center;
}

.company-structure-title {
  font-weight: 500;
  font-size: 40px;
  color: var(--primary-color);
  text-align: center;
  padding: 20px 0px 10px 0px;
  margin-bottom: 0px;
  border-radius: 10px;
}

.company-details-information {
  height: 55vh;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%); */
}

.infomation-item {
  min-height: 40%;
  background-color: #fff;
}

.infomation-button {
  display: flex;
  height: 10%;
  background-color: #fff;
  margin-top: 10px;
  padding: 5px;
  justify-content: space-around;
  align-items: center;
}

.button {
  min-width: 115px;
  padding: 5px;
}

.button:disabled {
  opacity: 0.6;
}

.button:disabled:hover {
  cursor: default !important;
  opacity: 0.6 !important;
}

.button-cancel {
  background-color: #e4e6ef;
  border-color: #e4e6ef;
  color: #3f4254;
}

.button:hover {
  opacity: 0.8;
}

.company-detail-notice-top {
  display: flex;
  background-color: #fff;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}

.company-detail-notice-top-dark,
.company-detail-notice-bottom-dark {
  color: var(--text-color-dark);
  background-color: var(--page-dark);
}

.company-detail-notice-bottom {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}

.notice-bottom-item {
  width: 100%;
  border-right: 1px solid #917151;
  margin: 8px;
}

.notice-bottom-item p {
  margin-bottom: 0;
}

.notice-bottom-item:last-child {
  border: none;
  margin-right: 0;
}

.notice-bottom-item:first-child {
  margin-left: 0;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #ebedf3;
  border-bottom-right-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
  justify-items: center;
}

.modal-footer-actions {
  display: flex;

  justify-items: center;
}

.modal-footer-actions .button {
  margin-top: 5px;
}

.input:disabled {
  background-color: rgb(55, 55, 53);
}

.company-detail-content-dark,
.company-structure-title-dark,
.company-detail-notice-top-dark,
.company-detail-notice-bottom-dark,
.modal-header-dark,
.modal-header-title-dark,
.modal-body-dark,
.infomation-item-dark,
.modal-footer-dark {
  color: #bebfc2 !important;
  background-color: #1e1e2c !important;
}

.modal-header-dark {
  border-top: none !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-bottom-color: #32383e !important;
}

.modal-footer-dark {
  border-top-color: #32383e !important;
}

.form-control-dark {
  color: var(--text-color-dark) !important;
  background-color: var(--page-dark) !important;
  border-color: #767e86 !important;
}

.btn-dark {
  background-color: var(--button-dark) !important;
  /* box-shadow: 0px 0px 6px 4px rgb(120 120 120 / 70%) !important; */
  /* border: none !important; */
  border: var(--button-border-dark) !important;
}

/* MQ STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.backdrop {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  top: 100px;
}

@media screen and (max-width: 1024px) {
  .notice-bottom-item {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ccc;
    margin: 8px;
  }

  .notice-bottom-item:last-child {
    border: none;
    margin-right: 0;
  }

  .company-detail-notice-bottom {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
  }
  .wrapper {
    min-height: 400px;
    margin-bottom: 0;
  }
  .wrapper li {
    margin-left: 0;
  }
}
@media screen and (max-width: 1550px) {
  .company-detail-content {
    flex-direction: column;
    justify-items: center;
  }
  .company-details-information {
    margin-top: 15px;
  }
}
@media screen and (max-width: 991px) {
  .company-detail-content {
    max-height: none;
  }

  .company-details-information {
    height: 100%;
    overflow: unset;
    flex-direction: column;
    margin-top: 15px;
  }

  .company-structure {
    padding: 8px;
  }

  .notice-bottom-item {
    margin-left: 0px;
    margin-right: 0px;
  }
  .button {
    font-size: 11px;
  }

  .actived {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .rectangle {
    padding: 20px 10px;
  }

  .level-1,
  .level-2 {
    width: 100%;
  }

  .level-1 {
    margin-bottom: 20px;
  }

  .level-1::before,
  .level-2-wrapper > li::before {
    display: none;
  }

  .level-2-wrapper,
  .level-2-wrapper::after,
  .level-2::after {
    display: block;
  }

  .level-2-wrapper li {
    width: 90%;
  }

  .level-2-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-2-wrapper::before {
    left: -20px;
    width: 2px;
    height: calc(100% + 40px);
  }

  .level-2-wrapper > li:not(:first-child) {
    margin-top: 50px;
  }

  .level-2-wrapper > li:first-child:not(:only-child):after {
    left: -20px;
    width: 2px;
    height: calc(100% + 50px);
  }

  .level-2-wrapper > li:not(:first-child):not(:last-child):after {
    left: -20px;
    width: 2px;
    height: calc(100% + 50px);
  }

  .level-2-wrapper > li:last-child:not(:only-child):after,
  .level-2-wrapper > li:only-child:after {
    left: -20px;
    width: 2px;
    height: 81px;
  }

  .level-2-wrapper::after {
    display: none;
  }
}
@media screen and (max-width: 522px) {
  .modal-footer-actions {
    margin-top: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .modal-footer {
    flex-direction: column;
    align-items: center;
  }
  .input-group {
    width: 200px;
  }

  .button {
    font-size: 12px;
  }

  .button-block {
    width: 100%;
  }
}

@media screen and (max-width: 370px) {
  .input-group {
    width: 130px;
  }
}
