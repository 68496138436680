.content-body {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  height: 66%;
}

.content-header {
  padding: 5px;
  text-align: center;
  font-size: 40px;
  background-color: #ffffff;
}

.content-header > p {
  padding: 10px;
  margin: 0;
  margin-top: 20px;
}

.qr-content {
  padding: 10px 5%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  font-size: 14px;
  border-right: 1px solid #c3b8c7;
}

.otp-field {
  width: 38px;
  padding-left: 12px;
  margin-left: 10px;
  height: 40px;
}

.otp-field::-webkit-outer-spin-button,
.otp-field::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.otp-field[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.otp-field:first-child {
  margin-left: 0px;
}

.title {
  text-align: center;
  font-weight: 600;
}

.title > span {
  font-weight: 400;
}

.qr-code {
  min-width: 200px;
  width: 50%;
  min-height: 200px;
}

.verify-content {
  width: 50%;
  padding: 10px 5%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.verify-input {
  text-align: center;
  width: 100%;
  outline: none;
  background-color: #f3f6f9;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #d1d3e0;
  border-radius: 6px;
  place-content: 'SADJFHASDLKF';
}

.verify-input::placeholder {
  color: #c3b8c7;
}

.verify-input:focus {
  border: 1px solid #cb1123;
  color: #3f4254;
}

.verify-button {
  margin-top: 20px;
}

.width-100-percent {
  width: 100%;
}

.padding-10-20 {
  padding: 10px 20%;
}

.flex-direction-column {
  flex-direction: column;
}

.width-400px {
  height: 64%;
  width: 100%;
  max-width: 400px;
}

.captcha-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hyperlink-message {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-decoration: underline;
}

.hyperlink-message:hover {
  font-size: 12px;
  font-weight: 600;
  color: white;
  text-decoration: underline;
}

.text-explain {
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 100%;
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border-radius: 8px;
}

.change-password-notice-message {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 2.5rem;
}

.click-here {
  display: inline-block;
  color: #8950fc;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 0;
}

.click-here:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 1023px) {
  .content-body {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .qr-content {
    width: 100%;
    border-right: none;
  }
  .verify-content {
    width: 100%;
  }
}

@media screen and (max-width: 739px) {
  .content-header {
    font-size: 30px;
  }
}

.file-arr-wrap {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: red;
  justify-content: space-between;
}

.close-file-icon {
  cursor: pointer;
  margin: 10px;
}

.close-file-icon:hover i {
  color: red;
}

.file-name-uploaded {
  margin: 0px;
  width: 28%;
  white-space: nowrap;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-files-input-fake {
  display: flex;
  align-items: center;
  background-color: #f3f6f9;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
}
