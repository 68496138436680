.product-redeem-wrapper {
  padding: 30px;

  .title {
    margin-bottom: 30px;
  }

  .content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;

    .alert {
      padding: 0 !important;
    }

    .form-wrapper {
      justify-content: center;

      form {
        .redeemValidate {
          margin-left: 1%;
          max-height: 300px;
          overflow-y: auto;

          p {
            font-size: 14px;
          }
        }

        label {
          &.dark {
            color: #bebfc2;
          }

          font-size: 14px;
          font-weight: 600;
          align-self: center;
        }

        .actions {
          margin-top: 20px;
          text-align: center;

          .actions-control {
            display: inline-flex;
            width: 100%;
          }

          .action-btn {
            &.dark {
              color: #bebfc2;
            }
            max-width: 160px;
            width: 100%;
          }
        }

        .required-symbol {
          font-size: 20px;
          color: #dede1a;
          position: absolute;
          top: 0px;
          right: -25px;
        }
      }

      .receipt-container {
        margin: auto;
        width: 100%;
        max-width: 315px;

        .receipt-content {
          border-radius: 7px;
          border: 1px solid #000;
          padding: 10px 20px 10px 20px;
          line-height: 10px;

          p {
            margin: 0 0 10px;
            text-align: left;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .actions-control {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
  }

  .action-btn:first-child {
    margin-bottom: 10px;
  }
}