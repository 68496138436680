.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  color: #3f4253;
  background-color: #ffffff;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgb(206 163 206 / 70%);
}
.wrapper-dark {
  color: #bebfc2;
  background-color: #1e1e2c;
}

.chart {
  min-height: 200px;
}

.wrapper-trans-table {
  margin-top: 20px;
  padding-top: 10px;
  color: #3f4253;
  background-color: var(--page-light);
  border-radius: 10px;
  box-shadow: var(--shadow);
}

.wrapper-trans-table-dark {
  color: var(--text-color-dark);
  background-color: var(--page-dark);
}

.table-dark,
.thead-dark {
  color: var(--text-color-dark) !important;
  background-color: var(--page-dark) !important;
}

.table-dark td,
.table-dark th {
  border-color: var(--border-td-th-table) !important;
}

.table-title-light {
  text-align: center;
  background-color: var(--page-light);
  color: var(--primary-color);
}

.table-title-dark {
  text-align: center;
  background-color: var(--page-dark);
  color: var(--text-color-dark);
}

.label-dark-success {
  color: #3ac489 !important;
  background-color: #1a323873 !important;
}

@media screen and (max-width: 739px) {
  .wrapper {
    align-items: center;
    flex-direction: column;
  }
}
